<template> 
    <div class="modal__input-area">
		<image-upload :add="true" @get-image="setImage($event)" />
		<div class="modal__input-container">
            <input v-model="name" id="id-name" class="modal__input" placeholder=" " type="text" ref="firstInput" />
            <label class="modal__label" for="id-name">name</label>
        </div>
		
		<label class="modal__check-label"><input class="modal__check" v-model="isNotEnd" type="checkbox" name="isend" id=""> Sub Category</label>
		<div class="modal__input-container">
            <textarea v-model="description" id="id-description" class="modal__description" placeholder=" "></textarea>
            <label class="modal__label" for="id-description">Description</label>
        </div>
		
		<div class="modal__buttons-container" >
			<button @click="createProductCategory" class="modal__button" :disabled="!validateNameInput">
                <div v-if="checking"><filling-circle :size="16" /></div>
                <div v-else>Add</div>
            </button>
		</div>
	</div>
</template>
<script>
import ImageUpload from '@/components/Modals/ModalInputs/ImageUpload.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import { isEmpty } from '@/helpers/FormValidation.js'
export default {
    components:{ ImageUpload,FillingCircle },
	data(){
		return {
			checking: false,
			name:"",
			isNotEnd:false,
			description:"",
			image: null,
		}
	},
	computed:{
		category_id(){
			return this.$route.params.id
		},
		is_end(){
			return Number(!this.isNotEnd)
		},
        validateNameInput(){
            return !isEmpty(this.name) 
        },
	},
	methods:{
		createProductCategory(){
			this.checking = true
			this.$store.dispatch({
                type:'products/createCategory',
                name: this.name,
                description: this.description,
				category_id: this.category_id,
				is_end:this.is_end,
                ...(this.image && {image:this.image})

            }).then(()=>{
                this.checking = false
                this.$router.go(-1)
				this.$notify({
                    title: `Product category created!`,
                    text: `you successfully created a product`,
                    type:"success"
                })

            }).catch( error =>{
                this.checking = false
				this.$notify({
					title: "Error creating branch",
					text: error,
					type:"error"
				})
            })
		},
		setImage(photo){
            this.image = photo
        },
	},
	mounted(){
		this.$refs.firstInput.focus();
	}
    
}
</script>