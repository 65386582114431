<template>
    <div class="modal-container">
		<div class="modal">
			<div class="modal__title">
				<h1>Add New Product Category</h1> 
				<button class="modal__close" @click="closeModal">
					<img class="modal__close-image" src="@/assets/images/close.svg" alt="">
				</button>
			</div>
			<product-input v-if="isProduct"/>
			<product-category-input v-else />
			
		</div>
	</div>
</template>
<script>
import ProductCategoryInput from '@/components/Modals/ModalInputs/ProductCategory.vue'
import ProductInput from '@/components/Modals/ModalInputs/Product.vue'


export default {
    props:{
        name: String,
    },
	components:{ ProductCategoryInput , ProductInput },

	methods:{
		closeModal(){
			this.$router.go(-1)
		}
	},

	computed:{
		isProduct(){
			return this.$store.state.products.isEnd
		}
	}

}
</script>
