<template>
    <div class="modal__input-area">
		<image-upload  :add="true" @get-image="setImage($event)" />
		<div class="modal__input-container">
            <input v-model="name" id="id-name" class="modal__input" placeholder=" " type="text" ref="firstInput">
            <label class="modal__label" for="id-name">name</label>
        </div>
		<div class="modal__input-container">
            <input v-model="batchcode" id="id-batchcode" class="modal__input" placeholder=" " type="text">
            <label class="modal__label" for="id-batchcode">Batchcode</label>
        </div>
		<div class="modal__input-container">
            <input v-model="unitCostPrice" id="id-unit-cost-price" class="modal__input" placeholder=" " type="text">
            <label class="modal__label" for="id-unit-cost-price">Unit Cost Price</label>
        </div>
		<div class="modal__input-container">
            <input v-model="unitMinimumSellingPrice" id="id-unit-min-selling-price" class="modal__input" placeholder=" " type="text">
            <label class="modal__label" for="id-unit-min-selling-price">Unit Minimum Selling Price</label>
        </div>
		<div class="modal__input-container">
            <input v-model="unitSellingPrice" id="id-unit-selling-price" class="modal__input" placeholder=" " type="text">
            <label class="modal__label" for="id-unit-selling-price">Unit Selling Price</label>
        </div>
		<div class="modal__input-container">
		<label class="modal__check-label"><input class="modal__check" v-model="trackConsignment" type="checkbox" name="isend" id="">Auto Track Consigment</label>
		</div>
		<div class="modal__input-container">
            <textarea v-model="description" id="id-description" class="modal__description" placeholder=" " type="text"></textarea>
            <label class="modal__label" for="id-description">Description</label>
        </div>
		
		<div class="modal__buttons-container">
			<button @click="createProduct" class="modal__button" :disabled="!formValidate">
                <div v-if="checking"><filling-circle :size="16" /></div>
                <div style="padding-left:1em;">{{ checking ? 'Creating...' : 'Add' }}</div>
            </button>
		</div>
	</div>
</template>
<script>
import ImageUpload from '@/components/Modals/ModalInputs/ImageUpload.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{ ImageUpload,FillingCircle },
	data(){
		return {
			checking: false,
			name:"",
			batchcode:"",
			trackExpiry:false,
			trackConsignment:false,
			unitCostPrice:"",
			unitSellingPrice:"",
			unitMinimumSellingPrice:"",
			description:"",
			image: "",
		}
	},
	computed:{
		categoryId(){
			return this.$route.params.id
		},
        formValidate(){
            return ( this.name )
        }
	},
	methods:{
		setImage(event){
			this.image = event
		},
		createProduct(){
			this.checking = true
			this.$store.dispatch({
                type:'products/createProduct',
                name: this.name,
                batch_code: this.batchcode,
                auto_track_consignment: this.trackConsignment,
                description: this.description,
				category: this.categoryId,
				unit_cost_price: this.unitCostPrice,
				unit_selling_price: this.unitSellingPrice,
				unit_minimum_selling_price: this.unitMinimumSellingPrice

            }).then(()=>{
                this.checking = false
				this.$notify({
                    title: `Product created!`,
                    text: `you successfully created a product}`,
                    type:"success"
                })
                this.$router.go(-1)

            }).catch( error =>{
                this.checking = false
				this.$notify({
					title: "Error creating product",
					text: error,
					type:"error"
				})
                console.log(error)
            })
		}
	},
	mounted(){
		this.$refs.firstInput.focus();
	}
    
}
</script>